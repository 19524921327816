// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 20px;
    background: #78aded;
    height: 100px;
    color: white;
}

.header-bar h1 {
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.user-greeting {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    right: 20px;
    top: 90px; /* Adjust based on the profile-icon size */
    background: white;
    color: #000;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 150px; /* Adjust as needed */
}

.dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}
.head{
    color: #333;
}`, "",{"version":3,"sources":["webpack://./src/styles/Common/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,SAAS;IACT,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS,EAAE,0CAA0C;IACrD,iBAAiB;IACjB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,YAAY,EAAE,qBAAqB;AACvC;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,WAAW;AACf","sourcesContent":[".header-bar {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    padding: 10px 20px;\n    background: #78aded;\n    height: 100px;\n    color: white;\n}\n\n.header-bar h1 {\n    margin: 0;\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n}\n\n.user-greeting {\n    position: absolute;\n    right: 20px;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n\n.profile-icon {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    cursor: pointer;\n}\n\n.dropdown-menu {\n    position: absolute;\n    right: 20px;\n    top: 90px; /* Adjust based on the profile-icon size */\n    background: white;\n    color: #000;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    z-index: 1000;\n    width: 150px; /* Adjust as needed */\n}\n\n.dropdown-item {\n    padding: 10px 20px;\n    cursor: pointer;\n    white-space: nowrap;\n}\n\n.dropdown-item:hover {\n    background-color: #f1f1f1;\n}\n.head{\n    color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
