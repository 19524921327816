// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hostel-info-container {
    padding: 20px;
}

.hostel-info-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.hostel-info-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.hostel-info-container th, .hostel-info-container td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.hostel-info-container th {
    background-color: #f2f2f2;
}

.hostel-info-container a {
    color: #007bff;
    text-decoration: none;
}

.hostel-info-container a:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Manager/HostelInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".hostel-info-container {\r\n    padding: 20px;\r\n}\r\n\r\n.hostel-info-container h2 {\r\n    text-align: center;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.hostel-info-container table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.hostel-info-container th, .hostel-info-container td {\r\n    border: 1px solid #ddd;\r\n    padding: 8px;\r\n    text-align: left;\r\n}\r\n\r\n.hostel-info-container th {\r\n    background-color: #f2f2f2;\r\n}\r\n\r\n.hostel-info-container a {\r\n    color: #007bff;\r\n    text-decoration: none;\r\n}\r\n\r\n.hostel-info-container a:hover {\r\n    text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
