// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manager-dashboard {
    display: flex;
}

.sidebar {
    width: 200px;
    background-color: #78aded;
    padding: 20px;
}

.sidebar h2 {
    margin-bottom: 20px;
}

.sidebar div {
    margin-bottom: 10px;
}

.sidebar a {
    text-decoration: none;
    color: black;
}

.content {
    flex-grow: 1;
    padding: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Manager/ManagerDashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".manager-dashboard {\n    display: flex;\n}\n\n.sidebar {\n    width: 200px;\n    background-color: #78aded;\n    padding: 20px;\n}\n\n.sidebar h2 {\n    margin-bottom: 20px;\n}\n\n.sidebar div {\n    margin-bottom: 10px;\n}\n\n.sidebar a {\n    text-decoration: none;\n    color: black;\n}\n\n.content {\n    flex-grow: 1;\n    padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
