// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    display: flex;
    flex-grow: 1;
}

.sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 20px;
}

.sidebar h2 {
    margin-bottom: 20px;
    color: white;
}

.sidebar div {
    margin-bottom: 10px;
}

.sidebar .side a {
    text-decoration: none;
    color: #000000;
    display: block;
    padding: 5px 0;
    transition: color 0.3s;
}

.sidebar .side a:hover {
    color: #ccc; /* Add hover effect */
}

.sidebar .side a.active {
    font-weight: bold; /* Make the active link bold */
    color: #000000;/* Ensure active link color is white */
}

.content {
    flex-grow: 1;
    padding: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Admin/AdminLayout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,cAAc;IACd,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,WAAW,EAAE,qBAAqB;AACtC;;AAEA;IACI,iBAAiB,EAAE,8BAA8B;IACjD,cAAc,CAAC,sCAAsC;AACzD;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".admin-layout {\r\n    display: flex;\r\n    flex-direction: column;\r\n    min-height: 100vh;\r\n}\r\n\r\n.main-content {\r\n    display: flex;\r\n    flex-grow: 1;\r\n}\r\n\r\n.sidebar {\r\n    width: 200px;\r\n    background-color: #f4f4f4;\r\n    padding: 20px;\r\n}\r\n\r\n.sidebar h2 {\r\n    margin-bottom: 20px;\r\n    color: white;\r\n}\r\n\r\n.sidebar div {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.sidebar .side a {\r\n    text-decoration: none;\r\n    color: #000000;\r\n    display: block;\r\n    padding: 5px 0;\r\n    transition: color 0.3s;\r\n}\r\n\r\n.sidebar .side a:hover {\r\n    color: #ccc; /* Add hover effect */\r\n}\r\n\r\n.sidebar .side a.active {\r\n    font-weight: bold; /* Make the active link bold */\r\n    color: #000000;/* Ensure active link color is white */\r\n}\r\n\r\n.content {\r\n    flex-grow: 1;\r\n    padding: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
