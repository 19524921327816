// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: white;
}

.login-box {
    background-color: #b4d0f2;
    padding: 100px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

.login-box h2 {
    margin-bottom: 20px;
    color: #333;
}

.login-box input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.password-container-login {
    position: relative;
}

.password-container-login input {
    padding-right: 11px; /* Ensure padding for eye icon */
}

.password-container-login .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.login-box button {
    width: 50%;
    margin-left:5%;
    padding: 10px;
    background-color: #2575fc;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-box button:hover {
    background-color: #334ff0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Common/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,mBAAmB;IACnB,wCAAwC;IACxC,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB,EAAE,gCAAgC;AACzD;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background: white;\n}\n\n.login-box {\n    background-color: #b4d0f2;\n    padding: 100px;\n    border-radius: 10px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    width: 300px;\n    text-align: center;\n}\n\n.login-box h2 {\n    margin-bottom: 20px;\n    color: #333;\n}\n\n.login-box input {\n    width: 100%;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n}\n\n.password-container-login {\n    position: relative;\n}\n\n.password-container-login input {\n    padding-right: 11px; /* Ensure padding for eye icon */\n}\n\n.password-container-login .eye-icon {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n}\n\n.login-box button {\n    width: 50%;\n    margin-left:5%;\n    padding: 10px;\n    background-color: #2575fc;\n    border: none;\n    border-radius: 5px;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.login-box button:hover {\n    background-color: #334ff0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
